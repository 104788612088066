import React, { useState, useEffect } from 'react';
import api from './api';
import './Contacts.css';


const Contacts = () => {
  const [contacts, setContacts] = useState([]);
  const [userPermissions, setUserPermissions] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [editableContact, setEditableContact] = useState(null);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [isNewContact, setIsNewContact] = useState(false);
  useEffect(() => {
    const fetchUserPermissions = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          console.error('No access token available');
          return;
        }
        const response = await api.get('/user/permissions', {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
        setUserPermissions(response.data.permissions);
        console.log(response.data.permissions);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          try {
            const refreshToken = localStorage.getItem('refresh_token');
            if (!refreshToken) {
              console.error('No refresh token available');
              return;
            }
            const refreshResponse = await api.post(`https://crm.acumentransit.com/refresh/?refresh_token=${refreshToken}`);
            localStorage.setItem('access_token', refreshResponse.data.access_token);
            // Retry fetching user permissions
            const retryResponse = await api.get('/user/permissions', {
              headers: {
                Authorization: `Bearer ${refreshResponse.data.access_token}`
              }
            });
            setUserPermissions(retryResponse.data.permissions);
            console.log(retryResponse.data.permissions);
          } catch (refreshError) {
            console.error('Error refreshing token:', refreshError.response ? refreshError.response.data : refreshError.message);
          }
        } else {
          console.error('Failed to fetch user permissions:', error.response ? error.response.data : error.message);
        }
      }
    };
    fetchUserPermissions();
  }, []);
  
  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    // Modify search to handle individual words
    const words = searchQuery.toLowerCase().split(' ').filter(word => word);
    const filtered = contacts.filter(contact =>
      words.every(word =>
        (contact.first_name && contact.first_name.toLowerCase().includes(word)) ||
        (contact.last_name && contact.last_name.toLowerCase().includes(word)) ||
        (contact.title && contact.title.toLowerCase().includes(word)) ||
        (contact.organization && contact.organization.toLowerCase().includes(word)) ||
        (contact.email && contact.email.toLowerCase().includes(word)) ||
        (contact.work_phone && contact.work_phone.toLowerCase().includes(word)) ||
        (contact.mobile_phone && contact.mobile_phone.toLowerCase().includes(word)) ||
        (contact.work_address1 && contact.work_address1.toLowerCase().includes(word))
      )
    );
    setFilteredContacts(filtered);
  }, [searchQuery, contacts]);

  const fetchContacts = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        console.error('No access token available');
        return;
      }
      const response = await api.get('/api/', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setContacts(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        try {
          const refreshToken = localStorage.getItem('refresh_token');
          if (!refreshToken) {
            console.error('No refresh token available');
            return;
          }
          const refreshResponse = await api.post(`https://crm.acumentransit.com/refresh/?refresh_token=${refreshToken}`);
          localStorage.setItem('access_token', refreshResponse.data.access_token);
          const retryResponse = await api.get('/api/', {
            headers: {
              Authorization: `Bearer ${refreshResponse.data.access_token}`
            }
          });
          setContacts(retryResponse.data);
        } catch (refreshError) {
          console.error('Error refreshing token:', refreshError.response ? refreshError.response.data : refreshError.message);
        }
      } else {
        console.error('Error fetching contacts:', error.response ? error.response.data : error.message);
      }
    }
  };

  const deleteContact = async (contactIds) => {
    // Ensure contactIds is always an array
    if (!Array.isArray(contactIds)) {
      contactIds = [contactIds];
    }

    // Catch the edge case where no contactIds have been passed
    if (contactIds.length === 0) {
      alert('Warning: No contacts have been selected for deletion.');
      return; // Exit the function early
    }

    // Determine the confirmation message based on the number of contacts
    const isConfirmed = window.confirm(
      `Are you sure you want to delete ${contactIds.length === 1 ? 'this contact' : 'these contacts'}?`
    );

    if (!isConfirmed) {
      return;
    }

    try {
      console.log('Deleting contacts with IDs:', contactIds); // Log the payload
      await api.delete('/api/contact/', { data: { contact_ids: contactIds } });
      fetchContacts();
    } catch (error) {
      console.error('Failed to delete contact:', error);
    }
  };

  const handleNameClick = (contact) => {
    setSelectedContact(contact);
    setEditableContact(contact);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleEditableContactChange = (e) => {
    const { name, value } = e.target;
    setEditableContact((prevContact) => ({
      ...prevContact,
      [name]: value,
    }));
  };

  const handleSort = (field) => {
    const direction = sortField === field && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortField(field);
    setSortDirection(direction);
  };

  const handleCheckboxChange = (contactId) => {
    setSelectedContacts(prevSelected =>
      prevSelected.includes(contactId)
        ? prevSelected.filter(id => id !== contactId)
        : [...prevSelected, contactId]
    );
  };

  const handleSelectAll = () => {
    if (selectedContacts.length === contacts.length) {
      setSelectedContacts([]);
    } else {
      setSelectedContacts(contacts.map(contact => contact.id));
    }
  };

  const downloadSelectedContacts = async (contacts, selectedContacts) => {
    try {
      const response = await api.post('/api/download/', { contact_ids: selectedContacts });
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'contacts.csv';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error('Failed to download contacts:', error);
    }
  };

  const sortedContacts = filteredContacts.sort((a, b) => {
    if (sortField) {
      const fieldA = a[sortField] ? a[sortField].toLowerCase() : '';
      const fieldB = b[sortField] ? b[sortField].toLowerCase() : '';
      if (fieldA < fieldB) return sortDirection === 'asc' ? -1 : 1;
      if (fieldA > fieldB) return sortDirection === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const addNewContact = () => {
    setIsNewContact(true);
    const newContact = {
      first_name: '',
      last_name: '',
      title: '',
      email: '',
      email_2: '',
      work_phone: '',
      mobile_phone: '',
      description: '',
      organization: '',
      work_address1: '',
      work_address2: '',
      work_po_box_sorting_code: '',
      work_city: '',
      work_county: '',
      work_us_state: '',
      work_zip_post_code: '',
      work_country: ''
    };
    setEditableContact(newContact);
    setSelectedContact(newContact);
  };

  const saveContact = async () => {
    try {
      if (isNewContact) {
        await api.post('/api/contact/', editableContact);
      } else {
        await api.put(`/api/contact/${editableContact.id}`, editableContact);
      }
      fetchContacts();
      setIsNewContact(false);
      setEditableContact(null);
      setSelectedContact(null); // Reset selectedContact to null
    } catch (error) {
      console.error('Failed to save contact:', error);
    }
  };

  return (
    <div className="contacts-container">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-bar"
      />
      {userPermissions >= 1 && (
        <>
          <button onClick={handleSelectAll} style={{ marginRight: '10px' }}>Select All</button>
          <button onClick={() => downloadSelectedContacts(contacts, selectedContacts)} style={{ marginRight: '10px' }}>Download Selected</button>
          <button onClick={addNewContact} style={{ marginRight: '10px' }}>Add Contact</button>
        </>
      )}
      {userPermissions >= 2 && (<button onClick={() => deleteContact(selectedContacts)}>Delete Selected</button>)}
      {selectedContact ? (
        editableContact ? (
          <div className="edit-contact-form">
            <h2>{isNewContact ? 'Add Contact' : 'Edit Contact'}</h2>
            <table>
              <tbody>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>First Name:</td>
                  <td>
                    <input
                      type="text"
                      name="first_name"
                      id="first_name"
                      value={editableContact.first_name}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>Last Name:</td>
                  <td>
                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      value={editableContact.last_name}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>Job Title:</td>
                  <td>
                    <input
                      type="text"
                      name="title"
                      id="title"
                      value={editableContact.title}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>Organization:</td>
                  <td>
                    <input
                      type="text"
                      name="organization"
                      id="organization"
                      value={editableContact.organization}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>Email:</td>
                  <td>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={editableContact.email}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>Office Phone:</td>
                  <td>
                    <input
                      type="text"
                      name="work_phone"
                      id="work_phone"
                      value={editableContact.work_phone}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>Mobile Phone:</td>
                  <td>
                    <input
                      type="text"
                      name="mobile_phone"
                      id="mobile_phone"
                      value={editableContact.mobile_phone}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>Work Address:</td>
                  <td>
                    <textarea
                      type="text"            
                      name="work_address1"
                      id="work_address1"
                      value={editableContact.work_address1}
                      onChange={handleEditableContactChange}
                      rows="4" // Adjust the number of rows as needed
                      style={{ width: '95%' }} // Adjust the width as needed
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>Address Line 2:</td>
                  <td>
                    <input
                      type="text"            
                      name="work_address2"
                      value={editableContact.work_address2}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>PO Box/Sorting Code:</td>
                  <td>
                    <input
                      type="text"            
                      name="work_po_box_sorting_code"
                      value={editableContact.work_po_box_sorting_code}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>City:</td>
                  <td>
                    <input
                      type="text"            
                      name="work_city"
                      value={editableContact.work_city}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>County:</td>
                  <td>
                    <input
                      type="text"            
                      name="work_county"
                      value={editableContact.work_county}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>State:</td>
                  <td>
                    <input
                      type="text"            
                      name="work_us_state"
                      value={editableContact.work_us_state}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>ZIP/Postal Code:</td>
                  <td>
                    <input
                      type="text"            
                      name="work_zip_post_code"
                      value={editableContact.work_zip_post_code}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>Country:</td>
                  <td>
                    <input
                      type="text"            
                      name="work_country"
                      value={editableContact.work_country}
                      onChange={handleEditableContactChange}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ textDecoration: 'underline' }}>Description:</td>
                  <td>
                    <textarea
                      name="description"
                      id="description"
                      value={editableContact.description}
                      onChange={handleEditableContactChange}
                      rows="4" // Adjust the number of rows as needed
                      style={{ width: '95%' }} // Adjust the width as needed
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <button onClick={saveContact}>Save</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div>Loading...</div>
        )
      ) : (
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>Select</th>
                <th style={{ cursor: 'pointer' }} onClick={() => handleSort('first_name')}>First Name <span className="sort-icon">⇅</span></th>
                <th style={{ cursor: 'pointer' }} onClick={() => handleSort('last_name')}>Last Name <span className="sort-icon">⇅</span></th>
                <th style={{ cursor: 'pointer' }} onClick={() => handleSort('title')}>Job Title <span className="sort-icon">⇅</span></th>
                <th style={{ cursor: 'pointer' }} onClick={() => handleSort('organization')}>Organization <span className="sort-icon">⇅</span> </th>
                <th style={{ cursor: 'pointer' }} onClick={() => handleSort('email')}>Email <span className="sort-icon">⇅</span></th>
                <th style={{ cursor: 'pointer' }} onClick={() => handleSort('work_phone')} >Work Phone <span className="sort-icon">⇅</span></th>
                <th style={{ cursor: 'pointer' }} onClick={() => handleSort('mobile_phone')} >Mobile Phone <span className="sort-icon">⇅</span></th>
                <th style={{ cursor: 'pointer' }} onClick={() => handleSort('work_address1')} >Work Address <span className="sort-icon">⇅</span></th>
                <th>Actions</th>
                {/* Add more columns as needed */}
              </tr>
            </thead>
            <tbody>
              {sortedContacts.map((contact, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedContacts.includes(contact.id)}
                      onChange={() => handleCheckboxChange(contact.id)}
                    />
                  </td>
                  <td 
                    style={{ cursor: userPermissions >= 1 ? 'pointer' : 'default' }} 
                    onClick={userPermissions >= 1 ? () => handleNameClick(contact) : null}
                  >
                    {contact.first_name}
                  </td>
                  <td 
                    style={{ cursor: userPermissions >= 1 ? 'pointer' : 'default' }} 
                    onClick={userPermissions >= 1 ? () => handleNameClick(contact) : null}
                  >
                    {contact.last_name}
                  </td>
                  <td>{contact.title}</td>
                  <td>{contact.organization}</td>
                  <td>{contact.email}</td>
                  <td>{contact.work_phone}</td>
                  <td>{contact.mobile_phone}</td>
                  <td>{contact.work_address1}</td>
                  <td>
                    {userPermissions >= 2 && <button onClick={() => deleteContact(contact.id)}>Delete</button>}
                  </td>                
                  {/* Render more fields as needed */}
                </tr>
              ))}
            </tbody>
            </table>
        </div>
      )}
    </div>
  );
};

export default Contacts;
